import { useEffect, useState } from 'preact/hooks';

export function useCartIdObserver(): { cartIds: Array<number> } {
  const [cartIds, setCartIds] = useState<Array<number>>([]);

  useEffect(() => {
    const handleMutations: MutationCallback = (
      mutationsList: MutationRecord[]
    ): void => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.target instanceof Element
        ) {
          const updatedIds = mutation.target.getAttribute('content');
          if (updatedIds) {
            setCartIds(JSON.parse(updatedIds).map(Number));
          }
        }
      }
    };

    const observer = new MutationObserver(handleMutations);
    const meta = document.querySelector('meta[name="cart-item-ids"]');
    const metaCartIds = meta?.getAttribute('content');

    // Set on first load
    if (metaCartIds) {
      setCartIds(JSON.parse(metaCartIds).map(Number));
    }

    if (meta) {
      observer.observe(meta, {
        attributes: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return { cartIds };
}
